import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import ButtonExternalLink from "../components/ButtonExternalLink"

const CaseStudy = ({children, title, buttonTo, bg, bgHex}) => {
    const renderStaticImage = () => {
        if (title === "Twig Design System") {
            return <StaticImage
            style={{
            gridArea: "1/1",
            height: '100%',
            }}
            layout="fullWidth"
            placeholder="blurred"
            aspectRatio={278 / 355}
            className="rounded-tl-xl rounded-bl-xl"
            alt=""
            src="../images/case-studies/twigds.jpg"
            formats={["auto", "webp", "avif"]}
        />;
        } else if (title === "s1jobs Application Form") {
            return <StaticImage
            style={{
            gridArea: "1/1",
            height: '100%',
            }}
            layout="fullWidth"
            placeholder="blurred"
            aspectRatio={278 / 355}
            className="rounded-tl-xl rounded-bl-xl"
            alt=""
            src="../images/case-studies/s1.jpg"
            formats={["auto", "webp", "avif"]}
        />;
        } else if (title === "StoriiCare User Research") {
            return <StaticImage
            style={{
            gridArea: "1/1",
            height: '100%',
            }}
            layout="fullWidth"
            placeholder="blurred"
            aspectRatio={278 / 355}
            className="rounded-tl-xl rounded-bl-xl"
            alt=""
            src="../images/case-studies/storii.jpg"
            formats={["auto", "webp", "avif"]}
        />;
        }
    
    }
    return (
        <div className={'flex flex-wrap rounded-xl shadow-lg text-white'}>
            <div className="w-full sm:w-1/3 rounded-tl-xl rounded-bl-xl">
                <div className="hidden sm:grid rounded-tl-xl rounded-bl-xl" style={{ height: '100%', background: '#' + bgHex + '' }}>
                    {renderStaticImage()}
                    <div
                        style={{
                        gridArea: "1/1",
                        position: "relative",
                        placeItems: "center",
                        display: "grid",
                        backgroundImage: 'linear-gradient(to right, transparent, #' + bgHex + ')',
                        backgroundRepeat: 'no-repeat',
                        height: 'auto',
                        }}
                    >
                        <div className="h-auto"></div>
                    </div>
                </div>
            </div>

            <div className={'w-full sm:w-2/3 p-10 rounded-tl-xl rounded-bl-xl sm:rounded-tl-none sm:rounded-bl-none rounded-tr-xl rounded-br-xl bg-gradient-to-r from-' + bg + '-900 to-' + bg + '-500 '}>
                <h2 className="text-lg font-bold">{title}</h2>

                <div className="max-w-prose py-8 text-gray-200 text-sm">
                    <p>{children}</p>
                </div>

                <div className="flex">
                    <div className="ml-auto">
                        <ButtonExternalLink label="View PDF" destination={buttonTo} colour={bg} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CaseStudy