import * as React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import CaseStudy from "../components/CaseStudy"

// markup
const CaseStudies = () => {
  return (
      <Layout>
        <div>
          <Container showFooter colour="0d1120" title="Case studies">
            <p className="mb-6 max-w-prose text-accent-text transition-color">Below are a few case studies from previous in-house design roles. They give a better idea of my overall design process. I'm happy to present these upon request.</p>

            <p className="mb-12 max-w-prose text-accent-text transition-color">If I've applied for a role, I'll have provided the password for these PDFs along with my application.</p>

            <div className="grid grid-cols-1 gap-12">
              <div>
              <CaseStudy buttonTo="../aj-gilmour-twig-case-study.pdf" title="Twig Design System" bg="purple" bgHex="4a4787">
                A case study around exploring, building and maintaining the Design System for Twig Education's Middle School science platform. Using Sketch, Abstract and Marvel for design, version control and handover, I explain the process in detail.
              </CaseStudy>
              </div>

              <div>
              <CaseStudy buttonTo="../aj-gilmour-s1-case-study.pdf" title="s1jobs Application Form" bg="green" bgHex="436b6d">
                A case study around the research and design work carried out when re-imagining the job application form for a regional jobs board. It discusses key metrics, prototype variants, the results from A/B testing and wider considerations.
              </CaseStudy>
              </div>

              <div>
              <CaseStudy buttonTo="../aj-gilmour-storiicare-case-study.pdf" title="StoriiCare User Research" bg="yellow" bgHex="D95100">
                A case study around exploring pain points directly with the end-users of an app aiming to make the lives of carers easier. I discuss in detail the processes used and the wider implications to the product direction and roadmap.
              </CaseStudy>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
  )
}

export default CaseStudies